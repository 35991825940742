
/* eslint-disable no-use-before-define */
import {
  computed,
  defineComponent, reactive, ref,
} from 'vue';
import { TradeObjectPreview } from '@/types/ObjectOfTrade';
import RegistryHeader from '@/components/views/registry/RegistryHeader.vue';
import UploadNewItems from '@/components/Modals/uploadNewItems.vue';
import api from '@/api';
import ViewportObserver from '@/components/ViewportObserver.vue';
import { ObjectStatus } from '@/constants/objectStatuses';
import usePagination from '@/modules/usePagination';
import { badgeProps } from '@/utils';
import { useStore } from 'vuex';
import useUgns from '@/modules/useUgns';
import { Worker } from '@/types/Ugnstno';

interface SelectorEmit<T> {
  id: number, field: keyof Worker, value: T
}

export default defineComponent({
  name: 'RegistryIndex',
  components: {
    UploadNewItems, RegistryHeader, ViewportObserver,
  },
  setup() {
    const error = ref('');
    const showUploadModal = ref(false);
    const store = useStore();

    const { ugns } = useUgns();
    const isRoleAdmin = store.state.auth.isAdmin;
    const listedOptions = ref([] as { value: number | undefined, label: string | undefined }[]);
    ugns.fetch().then(() => {
      listedOptions.value = ugns.options.map(({ value, name: label }) => ({ value, label }));
      listedOptions.value.shift();
    });

    const cols = computed(() => {
      const ugnsColumn = store.state.auth.isAdmin
        ? {
          label: 'УГНС',
          field: 'ugnsTnoCode',
          // field2: 'ugnsTnoCode',
          select: true,
          emit: 'change:ugns',
          width: '30%',
          options: {
            data: listedOptions.value,
            multiple: false,
            searchable: true,
            placeholder: 'Выбрать район',
            entityForCB: 'label',
            reduce: ({ label, value }: { label: string, value: number }) => ({ label, value }),
          },
        }
        : {
          label: 'Код Налогового Органа',
          display: (row: TradeObjectPreview) => row.ugnsTnoCode,
          width: '30%',
        };
      const changeStatusTradeObject = {
        component: (row: TradeObjectPreview) => ({
          name: 'ChangeStatus',
          ...badgeProps(row.objectStatus),
          'onUpdate:status': async (objectStatus: string) => {
            await api.objectsOfTrade.saveById(row.id, { objectStatus });
            objectsOfTrade.pagination.reset();
            await objectsOfTrade.fetch();
          },
        }),
      };

      return [
        isRoleAdmin === true ? changeStatusTradeObject : '',
        {
          label: 'Адрес',
          // width: '30%',
          component: (row: TradeObjectPreview): any => ({
            name: 'router-link',
            class: 'link',
            value: row.yandexAddressFull || row.userInput,
            to: { name: 'registry-trade-objects-object-id', params: { objectId: row.id } },
          }),
        },
        {
          label: 'тип помещения',
          display: (row: TradeObjectPreview) => row.buildingType || 'Не определено',
        },
        {
          label: 'Кол-во НП',
          display: (row: TradeObjectPreview) => row.nalogPayerCount,
        },
        ugnsColumn,
      ];
    });

    const objectsOfTrade = usePagination({
      filters: {
        address: computed(() => store.getters['registry/GET_REGISTRY_SEARCH']('tradeObjectSearch')),
        status: {} as ObjectStatus,
      },
      immediateFilters: ['status'],
      delayedFilters: ['address'],
      rows: [] as TradeObjectPreview[],
      async fetchContents() {
        const { address, status } = objectsOfTrade.filters as { address: string, status: ObjectStatus, };
        const statusValue = status?.value;
        const params = {
          ...address && { AddressOrInn: address },
          ...(typeof statusValue === 'string') && { status: statusValue },
          ...objectsOfTrade.pagination.params,
          duplicate: store.state.auth.isAdmin,
          pageSize: 100,
        };
        return (await api.objectsOfTrade.findAllPreview(params)).data;
      },
      async downloadExcel() {
        const { address, status } = objectsOfTrade.filters as { address: string, status: ObjectStatus, };
        const statusValue = status?.value;
        const params = {
          ...address && { AddressOrInn: address },
          ...(typeof statusValue === 'string') && { status: statusValue },
        };
        await api.objectsOfTrade.getExcelTradeObject(params);
      },
    });
    objectsOfTrade.fetch();

    const paginateHandler = (pageNumber: number) => {
      objectsOfTrade.pagination.page = pageNumber - 1;
      objectsOfTrade.fetch(false, true);
    };

    const pagination = reactive({
      pages: computed(() => objectsOfTrade.pagination.totalPages),
      currentPage: computed(() => objectsOfTrade.pagination.page + 1),
      fetching: computed(() => objectsOfTrade.fetching),
    });

    const changeUgnsHandler = async ({ id, value }: SelectorEmit<{ label: string, value: number }>) => {
      console.log(id, value);
      const objectOfTrade = (objectsOfTrade.rows as TradeObjectPreview[]).find((o) => o.id === id);
      if (!objectOfTrade) return;
      try {
        await api.objectsOfTrade.saveById(id, { ugnsTnoId: value.value });
        objectsOfTrade.pagination.reset();
        await objectsOfTrade.fetch();
      } catch (e) {
        alert('Что-то пошло не так');
      }
    };

    return {
      objectsOfTrade, error, cols, showUploadModal, paginateHandler, pagination, changeUgnsHandler,
    };
  },
});
